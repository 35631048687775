import React, { Component } from 'react';
import './index.css'
class Index extends Component {
    render() {
        return (
            <div className={"main"}>
               <div className="news-page">
                    <div className="b-title-left">资讯</div>
                   <div className="one-item">
                       <div className="left">
                            <div className="news-thumb">
                                <img src="/" alt=""/>
                            </div>
                           <div className="ant-col">
                               <div className="b-time">
                                   {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                                   <a data-v-05a3f5f0="" href="/news-detail/undefined" target="_blank"></a>
                               </div>
                               <div className="b-desc"></div>
                               <div className="b-detail">
                                   <a data-v-05a3f5f0="" href="/news-detail/undefined" target="_blank">查看详情 》</a>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
            </div>
        );
    }
}

export default Index;
