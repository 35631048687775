import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Head'; // 引入头部导航组件
import Saas from './components/SaaS'; // 导入SaaS组件
import Cases from './components/Cases'; // 导入Cases组件
import Solutions from './components/Solutions'; // 导入Solutions组件
import Consulting from './components/Consulting'; // 导入Consulting组件
import News from './components/News/index'; // 导入News组件
import Home from './components/Home';
import Footer from './components/Footer';
import About from './components/About'; // 导入About组件
import Signature from './components/Signature';
import InfoSecurity from './components/InfoSecurity';
import './App.css';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const onBeforeUnload = () => {
            window.scrollTo(0, 0);
        };

        window.addEventListener('beforeunload', onBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, []);

    return null;
}

function App() {
    return (
        <div className="App">
            <Router>
                <Header />
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/saas" element={<Saas />} />
                    <Route path="/cases" element={<Cases />} />
                    <Route path="/solutions" element={<Solutions />} />
                    <Route path="/consulting" element={<Consulting />} />
                    <Route path="/news" element={<News />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/infoSecurity" element={<InfoSecurity />} />
                    <Route path="/signature" element={<Signature />} />
                </Routes>
                <Footer/>
            </Router>
        </div>
    );
}

export default App;
