import React, {Component} from 'react';
import './index.css'

class Index extends Component {
    render() {
        return (
            <div className={"about-con2"}>
                <div className="info-tittle">
                    <span className={"tittle"}>信息安全</span>
                    <span className={"doc"}>保障用户隐私、数据安全是尚股服务的第一基准</span>
                </div>
                <div className="safe-content">
                    <div className="content-item">
                        <div className="desc-section">
                            <p className="title"> 合规安全 </p>
                            <div className="sub-box">
                                <div className="sub-title">政策合规</div>
                                <div
                                    className="desc"> 尚股根据国家信息安全相关法律、法规要求来制定实施程序，防止数据被不正当披露、篡改与破坏。
                                </div>
                            </div>
                        </div>
                        <div className="img-box">
                            <img src="/safe-1.png" alt=""/>
                        </div>
                    </div>
                    <div className="content-item">
                        <div className="img-box">
                            <img src="/safe-2.png" alt=""/>
                        </div>
                        <div className="desc-section">
                            <p className="title"> 数据安全 </p>
                            <div className="sub-box">
                                <div className="sub-title">传输安全</div>
                                <div className="desc"> 利用多层敏感数据传输，
                                    全程使用SSL加密，全面实现数据在传输过程中的安全。
                                </div>
                            </div>
                            <div className="sub-box">
                                <div className="sub-title">存储安全</div>
                                <div className="desc"> 数据库全部采用密文保存、数据隔离，即便系统管理人员也无法得到原文，且密钥可由企业用户掌握。
                                </div>
                            </div>
                            <div className="sub-box">
                                <div className="sub-title">数据销毁</div>
                                <div className="desc">对于需要销毁数据的客户，尚股会彻底清除其系统中所有的数据。
                                </div>
                            </div>
                            <div className="sub-box">
                                <div className="sub-title">安全审计</div>
                                <div className="desc">尚股详细跟踪记录所有的数据活动，实时分析语境和行为过滤，使所有用户操作有迹可寻。
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-item">
                        <div className="desc-section">
                            <p className="title"> 数据安全 </p>
                            <div className="sub-box">
                                <div className="sub-title">账号安全</div>
                                <div className="desc"> 尚股依托口令和访问控制策略，禁用弱口令，监控非法登录，同时使用短信验证码二次验证方式确保账户安全。
                                </div>
                            </div>
                            <div className="sub-box">
                                <div className="sub-title">暴力破解&撞库</div>
                                <div className="desc"> 尚股实时监测账号是否有破解、撞库与刷库等攻击行为，告警通知及处置恶意请求。
                                </div>
                            </div>
                            <div className="sub-box">
                                <div className="sub-title">系统登录日志</div>
                                <div className="desc">尚股详细记录用户的登录日志，用户可以查看上次登录的时间和地点。
                                </div>
                            </div>
                            <div className="sub-box">
                                <div className="sub-title">管理员操作日志</div>
                                <div className="desc">尚股提供详细的管理员操作日志，以记录系统的用户配置、权限配置等信息
                                </div>
                            </div>
                            <div className="sub-box">
                                <div className="sub-title">业务权限控制</div>
                                <div className="desc">尚股严格控制业务之间的权限隔离，保障用户仅能在权限范围内对其数据进行访问操作。
                                </div>
                            </div>
                            <div className="sub-box">
                                <div className="sub-title">WAF(Web应用防护系统)</div>
                                <div className="desc">尚股使用WAF对DDOS攻击，SQL注入等攻击手段进行严格的过滤和排除。
                                </div>
                            </div>
                        </div>
                        <div className="img-box">
                            <img src="/safe-3.png" alt=""/>
                        </div>
                    </div>
                    <div className="content-item">
                        <div className="img-box">
                            <img src="/safe-4.png" alt=""/>
                        </div>
                        <div className="desc-section">
                            <p className="title"> 数据安全 </p>
                            <div className="sub-box">
                                <div className="sub-title">系统安全</div>
                                <div className="desc"> A. 一旦安全团队跟踪发现服务器上的软件有安全漏洞，会通过应急漏洞修复。<br/>B.
                                    授权管理员需要先登录经过堡垒机之后，才能登录其他生产服务器。
                                </div>
                            </div>
                            <div className="sub-box">
                                <div className="sub-title">网络安全</div>
                                <div className="desc"> A.尚股通过VPC（虚拟私有网络）与外界网络隔离，并在VPC中划分公有子网和私有子网。
                                    <br/>B.不同子网及子网内不同主机分别通过ACL映射、安全组、iptables防火墙。
                                    <br/>C.所有终端的缓存加密策略遵循AES标准加密。
                                    <br/>D.尚股在CDN、反向代理服务和iptables等多个层面对DDos进行防护。
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-item">
                        <div className="desc-section">
                            <p className="title"> 基础设施安全 </p>
                            <div className="sub-box">
                                <div className="sub-title">账号安全</div>
                                <div className="desc"> 尚股系统使用了腾讯云的数据存储服务，可保障99.95%的服务可用性和99.999%的数据可靠性。
                                </div>
                            </div>
                        </div>
                        <div className="img-box">
                            <img src="/safe-5.png" alt=""/>
                        </div>
                    </div>
                    <div className="content-item">
                        <div className="img-box">
                            <img src="/safe-6.png" alt=""/>
                        </div>
                        <div className="desc-section">
                            <p className="title"> 灾难恢复与业务连续性 </p>
                            <div className="sub-box">
                                <div className="sub-title">灾备措施</div>
                                <div className="desc"> 尚股通过多副本存储策略，保障客户数据永不丢失并能7×24小时不间断运作。
                                </div>
                            </div>
                            <div className="sub-box">
                                <div className="sub-title">数据归档和导出服务</div>
                                <div className="desc"> 尚股系统支持客户数据的批量导出及报表输出等功能，可以确保客户数据最高的灵活度。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
