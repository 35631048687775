import React, {Component} from 'react';
import './index.css'

class Index extends Component {
    render() {
        return (
            <div className={"about-con2"}>
                <div className="info-tittle2">
                    <span className={"tittle"}>电子签章的合法、有效性</span>
                    <span className={"doc"}>尚股平台上所采用的电子签章技术由契约锁电子签约云平台提供</span>
                    <span className={"doc"}>尚股平通过国际领先的技术，保障用户的个人信息和数据的安全、合规有效</span>
                </div>
                <div className="top-con">
                    <div className="title"> 合规有效 </div>
                    <div className="section-item-box">
                        <div className="item">
                            <div className="icon">
                                <img src="/signature-icon1.png" alt=""/>
                            </div>
                            <div className="title2">身份认证</div>
                            <div className="desc">系统对接公安部和工商身<br/>份认证系统，保证认证身<br/>份</div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <img src="/signature-icon1.png" alt=""/>
                            </div>
                            <div className="title2">合同下载和查阅</div>
                            <div className="desc">详细记录合同签署过程， 形成日志可以方便下载，查阅。并提供第三方举证服务</div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <img src="/signature-icon1.png" alt=""/>
                            </div>
                            <div className="title2">公证处公证</div>
                            <div className="desc">联合公证处，司法鉴定中心，律所，仲裁委员会，为用户提供更全面的证据和司法服务</div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <img src="/signature-icon1.png" alt=""/>
                            </div>
                            <div className="title2">电子证据</div>
                            <div className="desc">完整的电子证据链条</div>
                        </div>
                    </div>
                </div>
                <div className="signature-content">
                    <div className="wrap-content">
                        <div className="left">
                            <div className="item">
                                <div className="item-title">
                                    符合规定
                                </div>
                                <div className="detail"> 相关智能印控硬件经过安全防护压测，符合国家相关规定</div>
                            </div>
                            <div className="item">
                                <div className="item-title">
                                    电子证据
                                </div>
                                <div className="detail"> 电子证据永久保全和托管</div>
                            </div>
                            <div className="item">
                                <div className="item-title">
                                    安全保障
                                </div>
                                <div className="detail"> 联合国家级CA中心，为用户提供专业数字证书、数字签名、时间戳服务，让数字证书更安全，更放心。</div>
                            </div>
                        </div>
                        <div className="right">
                            <img src="/signature-f.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
