import React, { Component } from 'react';
import './index.css'
class Index extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className={"solutions-con"}>
                <div className={"solutions-top-con"}>
                    <div className={"solutions-top-text"}>多元化解决方案供你选择</div>
                </div>
                <div className={"solutions-box"}>
                    <div className={"solutions-tittle"}>
                        <span className={"tittle"}>SaaS激励方案版</span>
                        <span
                            className={"substance"}>基于大数据与轻咨询服务，从方案设计到实施、管理的一站式互联网解决方案</span>
                    </div>
                    <div className="item-box">
                        <div className={"item"}>
                            <span className={"tittle"}>框架沟通</span>
                            <span className={"con"}>在线咨询尽调</span>
                            <span className={"con"}>资料收集分析</span>
                            <span className={"con"}>激励要点沟通</span>
                            <span className={"con"}>历史方案梳理</span>
                            <img src="/arrowhead.png" alt="" className={"img"}/>
                        </div>
                        <div className={"item"}>
                            <span className={"tittle"}>方案设计</span>
                            <span className={"con"}>行业大数据及科学算法参考建议</span>
                            <span className={"con"}>在线生成激励方案及要点说明</span>
                            <img src="/arrowhead.png" alt="" className={"img"}/>
                        </div>
                        <div className={"item"}>
                            <span className={"tittle"}>沟通与调整</span>
                            <span className={"con"}>系统生成激励效果测算表</span>
                            <span className={"con"}>智能配比方案定制化调整</span>
                            <img src="/arrowhead.png" alt="" className={"img"}/>
                        </div>
                        <div className={"item"}>
                            <span className={"tittle"}>方案定稿</span>
                            <span className={"con"}>生成股权激励方案说明文本</span>
                            <span className={"con"}>生成股权激励计划说明文本</span>
                            <img src="/arrowhead.png" alt="" className={"img-d"}/>
                        </div>
                    </div>
                    <div className="item-box2">
                        <div className={"item2"}>
                            <span className={"tittle"}>跟踪服务</span>
                            <span className={"con"}>专属顾问回访及答疑</span>
                            <span className={"con"}>全流程服务支持</span>
                            <img src="/arrowhead.png" alt="" className={"left-img"}/>
                        </div>
                        <div className={"item2"}>
                            <span className={"tittle"}>持续管理</span>
                            <span className={"con"}>可视化数据及文本详情查询导出</span>
                            <span className={"con"}>股权激励状态实时提醒</span>
                            <span className={"con"}>在线行权/回购</span>
                            <span className={"con"}>员工账户权益查询及展示</span>
                            <img src="/arrowhead.png" alt="" className={"left-img"}/>
                        </div>
                        <div className={"item2"}>
                            <span className={"tittle"}>在线实施</span>
                            <span className={"con"}>生成董事会决议、股东会决议、授予协议</span>
                            <span className={"con"}>激励计划在线授予及签章</span>
                            <img src="/arrowhead.png" alt="" className={"left-img"}/>
                        </div>
                        <div className={"item2"}>
                            <span className={"tittle"}>系统初始化</span>
                            <span className={"con"}>初始化系统及管理账号设置</span>
                            <span className={"con"}>股权激励方案数据导入</span>
                        </div>
                    </div>
                </div>
                <div className={"solutions-box2"}>
                    <span className={"solutions-text"}>SaaS智能管理版</span>
                    <span
                        className={"solutions-text2"}>支持境内外企业的股权激励计划全流程智能化管理的在线解决方案</span>
                    <div className="item-box">
                        <div className={"item"}>
                            <div className="item-one"/>
                            <div className="item-one">
                                <span>企业账号配置</span>
                                <span>企业认证</span>
                                <span>激励计划登记</span>
                                <span>协议等文本模版配置</span>
                            </div>
                            <div className="item-one"/>
                            <div className="item-one">
                                <span>员工账号配置</span>
                                <span>在线授予</span>
                                <span>在线行权</span>
                                <span>在线回购</span>
                            </div>
                            <div className="item-one"/>
                        </div>
                        <div className={"item"}>
                            <div className="item-one2">资料收集</div>
                            <div className="item-one2">系统初始化</div>
                            <div className="item-one2">系统培训</div>
                            <div className="item-one2">在线实施</div>
                            <div className="item-one2">全流程管理</div>
                        </div>
                        <div className={"item"}>
                            <div className="item-one">
                                <span>全流程管理</span>
                                <span>期权池大小</span>
                                <span>持股平台信息</span>
                                <span>期权协议</span>
                            </div>
                            <div className="item-one"/>
                            <div className="item-one">
                                <span>已初始化的各项数据演示</span>
                                <span>在线授予在线行权/回购等功能的操作演示</span>
                            </div>
                            <div className="item-one"/>
                            <div className="item-one">
                                <span>系统操作答疑</span>
                                <span>系统维护支持</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"solutions-box"} id={"item2"}>
                    <div className={"solutions-tittle"}>
                        <span className={"tittle"}>SaaS专家定制版</span>
                        <span className={"substance"}>咨询团队定制激励方案，在线化快速实施落地的一体化解决方案</span>
                    </div>
                    <div className="item-box">
                        <div className={"item"}>
                            <span className={"tittle"}>专家尽调</span>
                            <span className={"con"}>专家入场尽调</span>
                            <span className={"con"}>核心资料收集</span>
                            <span className={"con"}>行业研究分析</span>
                            <span className={"con"}>历史方案梳理</span>
                            <img src="/arrowhead.png" alt="" className={"img"}/>
                        </div>
                        <div className={"item"}>
                            <span className={"tittle"}>核心访谈</span>
                            <span className={"con"}>核心高管深度访谈</span>
                            <span className={"con"}>激励要点分析</span>
                            <img src="/arrowhead.png" alt="" className={"img"}/>
                        </div>
                        <div className={"item"}>
                            <span className={"tittle"}>框架沟通</span>
                            <span className={"con"}>系统生成激励效果测算表</span>
                            <span className={"con"}>智能配比方案定制化调整</span>
                            <img src="/arrowhead.png" alt="" className={"img"}/>
                        </div>
                        <div className={"item"}>
                            <span className={"tittle"}>定制方案</span>
                            <span className={"con"}>制作股权激励方案说明文本</span>
                            <span className={"con"}>设置股权激励计划及授予方案</span>
                            <span className={"con"}>激励效果测算表</span>
                            <span className={"con"}>方案宣讲及实施指导</span>
                            <img src="/arrowhead.png" alt="" className={"img-d"}/>
                        </div>
                    </div>
                    <div className="item-box2">
                        <div className={"item2"}>
                            <span className={"tittle"}>跟踪服务</span>
                            <span className={"con"}>专属顾问回访及答疑</span>
                            <span className={"con"}>全流程服务支持</span>
                            <img src="/arrowhead.png" alt="" className={"left-img"}/>
                        </div>
                        <div className={"item2"}>
                            <span className={"tittle"}>持续管理</span>
                            <span className={"con"}>可视化数据及文本详情查询导出</span>
                            <span className={"con"}>股权激励状态实时提醒</span>
                            <span className={"con"}>在线行权/回购</span>
                            <span className={"con"}>员工账户权益查询及展示</span>
                            <img src="/arrowhead.png" alt="" className={"left-img"}/>
                        </div>
                        <div className={"item2"}>
                            <span className={"tittle"}>在线实施</span>
                            <span className={"con"}>生成董事会决议、股东会决议、授予协议</span>
                            <span className={"con"}>激励计划在线授予及签章</span>
                            <img src="/arrowhead.png" alt="" className={"left-img"}/>
                        </div>
                        <div className={"item2"}>
                            <span className={"tittle"}>系统初始化</span>
                            <span className={"con"}>初始化系统及管理账号设置</span>
                            <span className={"con"}>股权激励方案数据导入</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
