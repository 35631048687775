import React, {Component} from 'react';
import './index.css'

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0 // 默认激活的索引为0
        };
    }

    handleItemClick = (index) => {
        this.setState({activeIndex: index});
    }
    componentDidMount() {
        // 添加类以触发动画
        document.querySelector('.consulting-top-con').classList.add('slide-animation');
    }

    renderSubstance = () => {
        const {activeIndex} = this.state;

        switch (activeIndex) {
            case 0:
                return <div className="substance">
                    <div className="one-box">
                        <div className="content-title">服务内容</div>
                        <div className="item-box">
                            <div className="item">
                                <img src="/platform-item-img1.png" alt=""/>
                                <div className="title">股权架构梳理</div>
                                <div className="desc">全面梳理企业的股权架构，视情况 进行股权结构调整，保证实际 控制人的控制权， 并预留后续的融资空间</div>
                                <div className="desc" id={"desc2"}>优化股权架构，确保控制权</div>
                            </div>
                            <div className="item">
                                <img src="/platform-item-img2.png" alt=""/>
                                <div className="title">地方优惠政策</div>
                                <div className="desc">全面梳理企业的股权架构，视情况 进行股权结构调整，保证实际 控制人的控制权， 并预留后续的融资空间</div>
                                <div className="desc" id={"desc2"}>优化股权结构，保障控制权"</div>
                            </div>
                            <div className="item">
                                <img src="/platform-item-img3.png" alt=""/>
                                <div className="title">股权架构梳理</div>
                                <div className="desc">全面梳理企业的股权架构，视情况 进行股权结构调整，保证实际 控制人的控制权， 并预留后续的融资空间</div>
                                <div className="desc" id={"desc2"}>优化股权架构，确保控制权和融资</div>
                            </div>
                        </div>
                    </div>
                    <div className="small-wrap" id={"img1"}>
                        <img src="/consluting-plan-sub-banner.png" alt=""/>
                    </div>
                    <div className="process">
                        <div className="small-wrap" id={"pro1"}>
                            <div className="process-title">服务流程</div>
                            <div className="process-text">
                                <div className="process-item">
                                    <img src="/process-item-img1.png" alt=""/>
                                    <div className="item-text"> 推荐企业至有税收优惠政策的 <br/>园区落地持股平台</div>
                                    <div className="item-text" id={"item-text2"}> 税优园区设平台 </div>
                                </div>
                                <div className="process-item">
                                    <img src="/process-item-img2.png" alt=""/>
                                </div>
                                <div className="process-item">
                                    <img src="/process-item-img3.png" alt=""/>
                                    <div className="item-text"> 提供持股平台植入主体公司的 <br/>全套法律协议模板</div>
                                    <div className="item-text" id={"item-text2"}> 法律协议模板提供</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item-box-last">
                        <div className="foot-title">服务流程</div>
                        <div className="item-foot">
                            <div className="item">
                                <img src="/item-foot-img1.png" alt=""/>
                                <div className="title">财税规划</div>
                                <div className="desc"> 结合地方优惠政策，为企业<br/> 提供合理的股权转让涉税<br/> 规划建议
                                </div>
                            </div>
                            <div className="item">
                                <img src="/item-foot-img2.png" alt=""/>
                                <div className="title">股权稳定</div>
                                <div className="desc"> 保持公司股权稳定，不因<br/> 员工离职等原因导致公司股权<br/> 频繁变更
                                </div>
                            </div>
                            <div className="item">
                                <img src="/item-foot-img3.png" alt=""/>
                                <div className="title">管理方便</div>
                                <div className="desc"> 通过入伙/退伙条件对<br/> 转让条件作出约定，更方便地<br/> 限制和管理
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;
            case 1:
                return <div className="substance">
                    <div className="substance-box1">
                        <div className="content-title">服务内容</div>
                        <div className="item-box" id={"item2-box"}>
                            <div className="item">
                                <img src="/service -content-img.png" alt=""/>
                                <div className="title">期权池分配测算</div>
                                <div className="desc"> 按需预留期权池大小<br/> 进行分岗分部门测算<br/> 分层级预留规划<br/> 充分考虑员工未来</div>
                                <div className="desc" id={"desc2"}>精准规划期权池，激发员工潜力</div>
                            </div>
                            <div className="item">
                                <img src="/service -content-img2.png" alt=""/>
                                <div className="title">激励范围与方式</div>
                                <div className="desc"> 结合公司战略确定<br/> 激励范围，提升战略层面<br/> 激励效用，综合考虑<br/> 公司发展阶段激励对象的<br/>战略重要性及入职时间<br/>推荐激励方式</div>
                                <div className="desc" id={"desc2"}>战略激励方案推荐，激发发展活力</div>
                            </div>
                            <div className="item">
                                <img src="/service -content-img3.png" alt=""/>
                                <div className="title">授予价格与额度</div>
                                <div className="desc"> 与公司现状及财税成本<br/> 相结合，确定不同<br/> 入职时间员工的授予<br/> 价格及授予额度</div>
                                <div className="desc" id={"desc2"}>差异化授予方案，提升员工激情</div>
                            </div>
                            <div className="item">
                                <img src="/service -content-img4.png" alt=""/>
                                <div className="title">时间和业绩条件</div>
                                <div className="desc"> 结合公司发展战略，协助<br/> 制定关键战略指标<br/> 及个人业绩指标，达到<br/> 激励约束一体化的目标</div>
                                <div className="desc" id={"desc2"}>定制关键绩效指标，助力激励达标</div>
                            </div>
                            <div className="item">
                                <img src="/service -content-img5.png" alt=""/>
                                <div className="title">回购与退出机制</div>
                                <div className="desc"> 前期设定员工权益<br/> 持有及退出机制，<br/> 未雨绸缪，保证各方利益</div>
                                <div className="desc" id={"desc2"}>制定员工权益计划，保障利益最大化</div>
                            </div>
                        </div>
                        <div className="small-wrap" id="img1">
                            <img src="/consulting-plan.png" alt=""/>
                        </div>
                        <div className={"custom-div"}>
                            <div className="process">
                                <div className="process-title">服务流程</div>
                                <div className="process-text">
                                    <div className="process-item">
                                        <img src="/process-item2-img1.png" alt="" />
                                        <div className="item-text">企业情况<br/> 尽职调查</div>
                                        <div className="item-text" id={"item-text2"}>尽职调查</div>
                                    </div>
                                    <div className="process-item">
                                        <img src="/process-item-img2.png" alt=""/>
                                    </div>
                                    <div className="process-item">
                                        <img src="/process-item2-img2.png" alt=""/>
                                        <div className="item-text">企业内部<br/> 深度访谈</div>
                                        <div className="item-text" id={"item-text2"}>深度访谈</div>
                                    </div>
                                    <div className="process-item">
                                        <img src="/process-item-img2.png" alt=""/>
                                    </div>
                                    <div className="process-item">
                                        <img src="/process-item2-img3.png" alt=""/>
                                        <div className="item-text">股权激励方案<br/> 设计与沟通</div>
                                        <div className="item-text" id={"item-text2"}>方案设计</div>
                                    </div>
                                    <div className="process-item">
                                        <img src="/process-item-img2.png" alt=""/>
                                    </div>
                                    <div className="process-item">
                                        <img src="/process-item2-img4.png" alt=""/>
                                        <div className="item-text">方案宣讲及<br/> 实施指导</div>
                                        <div className="item-text" id={"item-text2"}>实施指导</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="foot-content">
                            <div className="foot-title">服务优势</div>
                            <div className="item-foot">
                                <div className="item">
                                    <img src="/foot-img1.png" alt=""/>
                                    <div className="desc">根据企业不同阶段提供准确<br/>分配建议</div>
                                </div>
                                <div className="item">
                                    <img src="/foot-img2.png" alt=""/>
                                    <div className="desc">人力专家采用人力资本模型,<br/>结合薪酬、绩效定制授予方案据
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="/foot-img3.png" alt=""/>
                                    <div className="desc">结合公司战略规划，制定多个<br/>年度的长效激励方案据</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            case 2:
                return <div className="substance">
                    <div className="substance-box2">
                        <div className="content-title">服务内容</div>
                        <div className="item-box">
                            <div className="item">
                                <img src="/service -content1.png" alt=""/>
                                <div className="title">股权健康检查</div>
                                <div className="desc"> 对企业历史股权变更<br/> 进行全套税收风险诊断<br/> 找准税收痛点，提供解决方案</div>
                                <div className="desc" id={"desc2"}>识别历史税务风险，提供解决方案</div>
                            </div>
                            <div className="item">
                                <img src="/service -content2.png" alt=""/>
                                <div className="title" id={"title1"}>单一持股平台税收策划</div>
                                <div className="title" id={"title2"}>税收优化方案</div>
                                <div className="desc">帮助企业解决持股平台搭建<br/>和转移股权过程中原股东<br/>和持股平台的税收风险</div>
                                <div className="desc" id={"desc2"}>解决股权搭建与转移税收风险</div>
                            </div>
                            <div className="item">
                                <img src="/service -content3.png" alt=""/>
                                <div className="title">股权健康检查</div>
                                <div className="desc">从公司控制权及税收角度<br/>重新设计股权结构<br/>力求风险、成本、控制、管理的<br/>综合平衡</div>
                                <div className="desc" id={"desc2"}>重新设计股权结构，平衡风险成本</div>
                            </div>
                        </div>
                        <div className="small-wrap" id="img1">
                            <img src="/consulting-construct-sub-banner.png" alt=""/>
                        </div>
                        <div className="custom-div">
                            <div className="process">
                                <div className="process-title">服务流程</div>
                                <div className="process-text">
                                    <div className="process-item">
                                        <img src="/process-item2-img1.png" alt=""/>
                                        <div className="item-text"> 提供完善的股权结构设计 <br/> 方案，包括不限于股东身 <br/> 份、持股方式、份额影响<br/> 控制权管理等</div>
                                        <div className="item-text"  id={"item-text2"}> 优化股权方案</div>
                                    </div>
                                    <div className="process-item">
                                        <img src="/process-item-img2.png" alt=""/>
                                    </div>
                                    <div className="process-item">
                                        <img src="/process-item2-img2.png" alt=""/>
                                        <div className="item-text"> 根据企业实际情况提供详 <br/> 细操作步骤和注意事项， <br/> 让企业充分理解并正确 <br/> 操作，控制过程风险</div>
                                        <div className="item-text"  id={"item-text2"}>全面操作指导</div>
                                    </div>
                                    <div className="process-item">
                                        <img src="/process-item-img2.png" alt=""/>
                                    </div>
                                    <div className="process-item">
                                        <img src="/process-item2-img3.png" alt=""/>
                                        <div className="item-text"> 提供方案执行的落地实施 <br/> 或全程指导，全面控制细 <br/> 节，以求企业综合利益最 <br/> 大化</div>
                                        <div className="item-text"  id={"item-text2"}>最大化利益</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="foot-content">
                            <div className="foot-title">服务优势</div>
                            <div className="item-foot">
                                <div className="item">
                                    <img src="/foot-content-img1.png" alt=""/>
                                    <div className="desc">
                                        具有十年以上经验的税务专家定制方案
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="/foot-content-img2.png" alt=""/>
                                    <div className="desc">
                                        与园区政府战略合作保证政策落地实施
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;
            case 3:
                return <div className="substance">
                    <div className="substance-box2">
                        <div className="content-title">服务内容</div>
                        <div className="item-box">
                            <div className="item">
                                <img src="/service -content-top1.png" alt=""/>
                                <div className="title" id={"title1"}>专业股权激励宣讲</div>
                                <div className="title" id={"title2"}>专业激励宣讲</div>
                                <div className="desc"> 进行专业的股权激励宣讲<br/> 提高激励效果<br/></div>
                                <div className="desc" id={"desc2"}> 进行专业的股权激励宣讲提高激励效果</div>
                            </div>
                            <div className="item">
                                <img src="/service -content-top2.png" alt=""/>
                                <div className="title">案例分析辅导</div>
                                <div className="desc">经典案例分析并进行辅导学习</div>
                                <div className="desc" id={"desc2"}>经典案例分析并进行辅导学习</div>
                            </div>
                            <div className="item">
                                <img src="/service -content-top3.png" alt=""/>
                                <div className="title" id={"title1"}>员工系统操作培训</div>
                                <div className="title" id={"title2"}>员工操作培训</div>
                                <div className="desc">对企业员工进行系统操作<br/>相关培训</div>
                                <div className="desc" id={"desc2"}>对企业员工进行系统操作相关培训</div>
                            </div>
                            <div className="item">
                                <img src="/service -content-top4.png" alt=""/>
                                <div className="title" id={"title1"}>企业员工操作培训</div>
                                <div className="title" id={"title2"}>员工操作培训</div>
                                <div className="desc">由经验丰富的股权专家<br/>为员工答疑</div>
                                <div className="desc" id={"desc2"}>由经验丰富的股权专家为员工答疑</div>
                            </div>
                        </div>
                        <div className="small-wrap" id={"img1"}>
                            <img src="/consulting-profit-sub-banner.png" alt=""/>
                        </div>
                        <div className="custom-div">
                            <div className="process">
                                <div className="process-title">服务流程</div>
                                <div className="process-text">
                                    <div className="process-item">
                                        <img src="/service -content-foot1.png" alt=""/>
                                        <div className="item-text">宣讲方案定制</div>
                                    </div>
                                    <div className="process-item">
                                        <img src="/process-item-img2.png" alt=""/>
                                    </div>
                                    <div className="process-item">
                                        <img src="/service -content-foot2.png" alt=""/>
                                        <div className="item-text"> 股权专家宣讲</div>
                                    </div>
                                    <div className="process-item">
                                        <img src="/process-item-img2.png" alt=""/>
                                    </div>
                                    <div className="process-item">
                                        <img src="/service -content-foot3.png" alt=""/>
                                        <div className="item-text"> 系统演示</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;
            default:
                return null;
        }
    }

    render() {
        const {activeIndex} = this.state;

        return (
            <div className={"consulting-con"}>
                <div className={"consulting-top-con"}>
                    <div className={"consulting-top-text"}>股权激励专项咨询服务</div>
                    <div className={"consulting-top-text2"}>由专业的咨询团队，为您提供股权激励相关的专项咨询服务</div>
                </div>
                <div className="nav">
                    <div className="small-wrap" id={"menu1"}>
                        <div className="nav-item cursor" onClick={() => this.handleItemClick(0)}>
                            <span className={"item-title"}>持股平台搭建</span>
                            {activeIndex === 0 && <div className="line"></div>}
                        </div>
                        <div className="nav-item cursor" onClick={() => this.handleItemClick(1)}>
                            <span className={"item-title"}>股权激励方案设计</span>
                            {activeIndex === 1 && <div className="line"></div>}
                        </div>
                        <div className="nav-item cursor" onClick={() => this.handleItemClick(2)}>
                            <span className={"item-title"}>股权结构设计及税收规划</span>
                            {activeIndex === 2 && <div className="line"></div>}
                        </div>
                        <div className="nav-item cursor" onClick={() => this.handleItemClick(3)}>
                            <span className={"item-title"}>员工收益宣讲</span>
                            {activeIndex === 3 && <div className="line"></div>}
                        </div>
                    </div>
                </div>
                {this.renderSubstance()}
            </div>
        );
    }
}

export default Index;
