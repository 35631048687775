import React from 'react';
import './index.css'
const Footer = () => {
    return (
        <div className={"footer"}>
            <div className={"wrap"}>
                <div className={"title display-sb flex-justify-between"}>
                    <img src="/logo/尚股字母方案1.svg" alt="" className={'img-footer'}/>
                    <div className="menu">
                        <ul>
                            <li><a href="/about">关于我们</a></li>
                            {/*<li><a href="#">法律支持</a></li>*/}
                            <li><a href="/infoSecurity">信息安全</a></li>
                            <li><a href="/signature">电子签章</a></li>
                        </ul>
                    </div>
                </div>
                <div className={"footer-description flex-justify-between"}>
                    <div className={"footer-description-left"}>
                        {/*<div className={"footer-left-item flex-justify-center flex-justify-start"}>*/}
                        {/*    <img src="/footer-left-img1.png" alt="" className={'footer-left-img'}/>*/}
                        {/*    <span>联系我们：18522077455</span>*/}
                        {/*</div>*/}
                        <div className={"footer-left-item flex-justify-center flex-justify-start"} id={"address"}>
                            <img src="/footer-left-img1.png" alt="" className={'footer-left-img'}/>
                            <span>商务合作：marketing@sungo.top</span>
                        </div>
                        <div className={"footer-left-item flex-justify-center flex-justify-start"} id={"address"}>
                            <img src="/footer-left-img1.png" alt="" className={'footer-left-img'}/>
                            <span>成都市武侯区天府大道北段1677号交子金融中心B座502室</span>
                        </div>
                    </div>
                    <div className={"footer-description-right"} id={"email"}>
                        <input type="email" placeholder="电子邮件" className="email-input"/>
                        <button className="apply-btn">申请试用</button>
                    </div>
                </div>
                <div className={"flex-align-center"}>
                    <span className={"footer-code"}>尚股 蜀ICP备2022026067号-1</span>
                    <span className={"footer-code"}>
                        <img src="/gan.png" alt=""/>
                        <span>川公网安备 51019002005222号</span>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Footer;
