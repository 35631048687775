import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import './index.css';

function Header() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1660);
    useEffect(() => {
        console.log('windowinnerWidth233', window.innerWidth, window.innerWidth < 1660);
    }, [])
    return (
        <div className={"header-con"}>
            <div className="header">
                <div className={"menu-container"}>
                <span className="menu-logo">
                    <img src="/logo/尚股字母方案1.svg" alt="Logo" className="logo" onClick={() => {
                        window.location.href = "/"
                    }}/>
                    {!isMobile && <ul className="menu">
                        <li className="menu-item">
                            <NavLink to="/saas">SaaS产品</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to="/cases">案例展示</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to="/solutions">解决方案</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to="/consulting">咨询服务</NavLink>
                        </li>
                        {/*<li className="menu-item">*/}
                        {/*    <NavLink to="/news">资讯</NavLink>*/}
                        {/*</li>*/}
                        <li className="menu-item">
                            <NavLink to="/about">关于我们</NavLink>
                        </li>
                    </ul>}
                    {isMobile && <ul className="menu">
                        <li className="menu-item">
                            <NavLink to="/saas">SaaS</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to="/cases">案例</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to="/solutions">方案</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to="/consulting">咨询</NavLink>
                        </li>
                        {/*<li className="menu-item">*/}
                        {/*    <NavLink to="/news">资讯</NavLink>*/}
                        {/*</li>*/}
                        <li className="menu-item">
                            <NavLink to="/about">我们</NavLink>
                        </li>
                    </ul>}
                    <div className="buttons" id={"buttons"}>
                    <button className="login-btn" onClick={() => window.open('https://app.sungo.top/', '_blank')}>登录
                    </button>
                    <button className="free-btn"
                            onClick={() => window.open('https://demoweb.sungo.top', '_blank')}>免费使用
                    </button>
                </div>
                </span>
                </div>
            </div>
        </div>
    );
}

export default Header;
